// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#what-are-pwas"
        }}>{`What are PWAs?`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#cross-device-push-notifications"
        }}>{`Cross Device Push Notifications`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#service-worker"
        }}>{`Service Worker`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Surprisingly, the Progressive Web App topic in 2021 is still not well known. In general, the people aware about the keys
concepts around it are most likely the ones who had already the chance to "touch it" with a project, a product,
by reading a blog or a with a PR or a MRˆˆ.`}</p>
    <p>{`The others can still think that "P.W.A" is another fancy acronym to describe something not really new in order
to make it interesting.`}</p>
    <p>{`It is almost true... ˆˆ but not completely. Some fundamentals changes are brought on the table by these PWAs and
if you are among the one who are going to discover this subject through a blog, I will try to give you the big picture.`}</p>
    <h2 id="what-are-pwas">
  What are PWAs?
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Progressive Web Apps are a new kind ﻿of Web applications that use the latest Web APIs. They are still aiming
a mobile first approach like any other regular "responsive" application but on top of focusing
on the "responsiveness" of the app, they are providing them a set of superpowers that their older sisters doesn't have.`}</p>
    <p>{`The goal is to make them immersive as if they were native applications you would download from the IOS App store
or the Android Play store.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/wallys_blog_pwa.png')} loading="lazy" alt="Wally's blog pwa" description="Wally's blog pwa image installation example" />
    <p>{`The ones following all the P.W.A criterias can offer to users an experience so close to a native application,
that it could be impossible to tell if some apps were implemented with plain old HTML, CSS, Javascript
or native code like Kotlin and Swift.`}</p>
    <h2 id="cross-device-push-notifications">
  Cross Device Push Notifications
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`On top of creating an immersive user experience by having this web app among the others apps on your phone,
tablet or desktop, one of the key features from my point of view connecting the dots in the users minds
is the fact that the PWAs are capable to push notifications on your devices.
You can notify your users even they are not using your application.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/push_notifications.png')} loading="lazy" alt="push notifications" description="image showing a cross device notification" />
    <p>{`For example you want to remind them that you have an amazing discount for them or any other interesting thing
that you would propose them in order to keep them engaged and interacting with your app or product.`}</p>
    <h2 id="service-worker">
  Service Worker
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`This feature and others key ones won't be possible without the help of service workers.
It is basically an another piece of code that will run as a background service in the application.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/sw-lifecycle.png')} loading="lazy" alt="Service Worker Lifecycle" description="image showing a small diagram of a Service Worker Lifecycle" />
    <p>{`It will help you to intercept, control and play with the Netw﻿ork or the cache for example, in a hidden
and invisible way to your users.`}</p>
    <p>{`So you will be able to tailor a user experience as smooth as if you were not relying on an internet connection
and keep engaging them even if they are Offline.
According to the level of engagement you are aiming and trying to reach, which at the end could be potentially
driven by your app context, you will be offered a specific Caching strategy (Service worker caching strategies).`}</p>
    <p>{`Their goal is the same one, have a fast and smooth user experience. Without any latency issue or others
disruptions that could happen when you are letting that in the hands of the users chance.`}</p>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`So to conclude on this topic, I would say that if you want reduce the bounce rate of your app, increase the monthly
active users, increase the growth, increase the conversion rate, reduce the loading time of your website﻿,
reduce the size of your application and others things like these...`}</p>
    <p>{`I encourage you to deep dive on the keys concepts that will help you to transform your app to Progressive Web App
or to build one!`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   